.component-phone-data { /*cadre de tous les éléments*/
  position: flex;
}

.results-subtitle { /*texte information*/
  text-align: center;
  position: flex;
  color:#444444;
  font-size: 16px;
  margin-bottom: 24px;
}

/* Container de l'image / pièces / remise / total */
.phone-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  gap: 16px;
}

.component-phone-data-picture img{
  height: 400px;
  padding: 5px;
}

/* Boite affichage pièces et boite affichage prix */
.phone-data-info-box {
  align-items: center!important;
  display: flex;
  font-size: 17px;
  width: 450px;
}

.box-protection{
  width:100%;
}

/* Boite affichage protection et boite affichage prix */
.protection-data-info-box {
  align-items: center!important;
  display: flex;
  font-size: 17px;
  width: 450px;
}

/* Libellé d'une boite générique */
.info-box-label {
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap:10px;
}
/* Libellé de la boite de protection */
.info-box-label-proctection{
  padding: 15px 0px 5px 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
}

.picto-piece{
  width: 28px
}

.picto-protection{
  width: 58px
}

.info-texte{
  text-align: center;
  margin-top: 10%;
  padding: 0% 2% 0 2%;
  width: 450px
}

/* Prix d'une boite générique */
.info-box-price {
  padding: 10px;
  margin-left: auto !important;
  display: flex;
  align-items: center;
  gap: 13px;
}

.info-box-price-protection{
  padding: 0px 10px;
  margin-left: auto !important;
  display: flex;
  grid-gap: 13px;
  gap: 13px;
  align-items: center;
  justify-content: flex-end;
}

.checkmark {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
}

/* Boite affichage pièces */
.item-info-box {
  margin-bottom: 10px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  gap: 5px;
}

  .item-info-box-protection{
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    grid-gap: 5px;
    gap: 5px;
  }

input[type=checkbox].item-checkbox {
  display: none;
}

.item-info-box:hover { /*bord de la boite passage souris*/
  border: 1px solid #003399 ;
}

.lab>input:checked+.item-info-box>*:last-child>.checkmark{
  height: 25px;
  border-radius: 5px;
  border: 0px solid #0019ff;
  content: url(https://static.wixstatic.com/media/787bb2_f32765ffee344781b2da8c61f5cb3315~mv2.jpg);
}

.lab>input:checked+.item-info-box { /*bord de la boite sélectionnée*/
  border: 0.0625rem solid #003399;
  background: #FFFFFF;
}

.item-info-box-protection:hover { /*bord de la boite passage souris*/
  border: 1px solid #003399 ;
}

.lab>input:checked+.item-info-box-protection>*:last-child>.checkmark{
  height: 25px;
  border-radius: 5px;
  border: 0px solid #0019ff;
  content: url(https://static.wixstatic.com/media/787bb2_f32765ffee344781b2da8c61f5cb3315~mv2.jpg);
}

.lab>input:checked+.item-info-box-protection { /*bord de la boite sélectionnée*/
  border: 0.0625rem solid #003399;
  background: #FFFFFF;
}

/* Container boites prix (remise et total) */
.component-phone-data-prices {
  margin-bottom: 10px;
}

/* Boite affichage prix (remise et total) */
.amount-info-box {
  margin-top: 10px;
  margin-bottom: -10px;
  padding: 10px;
  background: #F8F8F9;
  border-radius: 10px;
}

.text-protection{
  margin: 0px;
  padding-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .component-phone-data {
    width: 100%;
    margin: auto;
  }

  .component-phone-data-picture {
    display: none;
  }

  .phone-data-info-box {
    width: auto;
  }
}

/* Gestion du choix des couleurs de pièces */
input[type=radio] {
  display: none;
}

.colorOptions {
  display: flex;
}

.cardRadio+.cardColorBorder { /*cercle autour du bouton choix de couleur*/
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #D9D9D9;
  position: absolute;
  z-index: 10;
}

.cardColor { /*position du cercle de couleur dans le choix de couleur*/
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 3.5px;
  margin-left: 3.5px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.cardColorBorder:hover { /*souris sur le choix de la couleur*/
  cursor: pointer;
  border: 1px solid #686D74 ;
}

.cardRadio:checked+.cardColorBorder { /*sous bouton choix de couleur*/
  border: 1px solid #205685;
}

@media screen and (max-width:900px) {
  .phone-data-info-box {
    width: auto;
  }

  .protection-data-info-box {
    width: auto;
  }

  .info-texte{
    width: auto;
  }
}
