.component-recap-page { /*cadre de tous les éléments de la page RecapPage.js */
    width: auto;
    margin: 0 24.5%;
}

.title-DataForm{
    text-align: center;
    color: #003399;
    box-sizing: border-box;
    font-size: 27px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin: 20px 0px 24px;
}

.sub-info{
  background: #f2eceB;
      padding: 25px 59px;
      border-radius: 13px;
      color: black;
      text-align: center;
      margin-top: 29px;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
}

.component-recap-page-info {
  font-size: 18px;
  margin: 26px 10px 16px;
  text-align: center;
  color: black;
  font-family: 'Roboto', sans-serif
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
}

.item-protection-recapPage{
    display: flex;
    align-items: center !important;
    font-size: 17px;
    width: 100%;
    margin-bottom: 30px;
    cursor: auto;
    border-radius: 5px;
    grid-gap: 5px;
    gap: 5px;
    background: #f2eceB;
}

.picto-protection-recapPage{
    padding: 10px 5px;
    width: 75px;
    display: flex;
}

.info-box-label-proctection-recapPage{
    padding: 0px;
      display: flex;
      font-size: 18px;
      flex-direction: row-reverse;
      text-align: center;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
}

.component-recap-page-title {
  text-align: center;
  color: #003399;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.component-recap-bloc { /*Bloc gris comprenant l'image, le modèle et le prix */
  display: grid;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 25px 10px;
  background: #f2eceB;
  gap:16px;
}

.component-recap-bloc-image {
  grid-column: 1;
  display: flex;

}

.component-recap-bloc-image img {
  width: 100%;
  object-fit: contain;
  padding: 5px;
}

.component-recap-bloc-model {
  grid-column: 2;
  align-self: center;
  max-width: 260px;
  min-width: 260px;
  text-align: start;
  color: black;
}

.component-recap-bloc-model-title {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 20px;
}

.component-recap-bloc-prices{
  display: flex;
  grid-column: 3;
  align-self: center;
  padding-left: 10px;
  /* border-left: 3px solid #D9D9D9; */
  margin: 32px 0px;
  justify-content: space-between;
}

.component-recap-bloc-totalAmount {
  font-weight: bold;
}

.component-recap-add-info {
  display: grid;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 15px 10px 8px 10px;
  border-bottom: 1px solid rgb(0 0 0);
  color: #000000;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .component-recap-page {
    width : auto;
    margin: 0 2%;
  }

  .sub-info{
    padding: 25px;
  }

  .component-recap-bloc {
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 1 / 2;
  }

  .component-recap-bloc-image {
    display: none;
  }

  .component-recap-bloc-model {
    /* grid-column: 1; */
    padding: 10px;
    text-align: center;
  }

  .component-recap-bloc-prices {
    padding: 7px;
    font-size: 20px;
  }
}

.component-promo-code { /* Composant avec la case code de réduction + bouton validation */
  padding-bottom: 15px;
}

#validate-promo-code-button {
  min-width: 100%;
  padding: 0 10px;
  margin-top: 10px;
  width: 10%;
}

@media screen and (max-width: 767px) {
  #validate-promo-code-button {
    font-size: 15px;
  }
}
