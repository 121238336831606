.component-search-input > div {
  display: flex;
  margin: 0 15vw 5vw 15vw;
  padding-bottom: 0px;
  align-items: center;
}

.component-search-input input {
  border: 0px solid #D9D9D9;
  box-sizing: border-box;
  font-size: 15px ;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  width: 100%;
}

.component-search-box {
  display: flex;
  padding: 0px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.input-prefix{
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  color: #003399;
  padding: 9px 13px;
}

.img-search{
  height: 24px;
  transform: scaleX(-1);
  image-orientation: revert;
}

::placeholder{
  color: black;
}

::-ms-input-placeholder{
  color: black;
}

Input:focus{
  border-color: #205685;
}

Input{
  outline: none;
}

@media screen and (max-width: 767px) {
  .component-search-input > div {
    margin: 21px auto 25px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width:1100px) {
  .component-search-input > div {
    margin: 0 5vw 4vw 5vw;
    padding-bottom: 0px;
  }
}
