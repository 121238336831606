.header-main {
    display: flex;
    flex-direction: column;
    height: 100px;
    background: white;
    box-shadow: 0 3px 10px rgba(0,0,0,.1);
    z-index: 1000;
}

.main-hearder .app__header {
    position: fixed;
    top: -1px;
    left: 0;
    width: 100%;
    height: 56px
}

.logo {
  width: 195px;
  margin-top: 6px;
}

.img-logo{
  width: 70%;
}

.header-main_wrapper{
  max-width: 1740px;
  padding: 0 20px;
  margin: 0 auto;
}

.header-main_header{
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  box-sizing: border-box;
}

.header-main_nav{
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  font: var(--fnt,var(--font_1));
  font-weight: 600;
  font-size: 15px;
  padding: 0 5px
}

.header_wrapper{
    max-width: 1740px;
    padding: 0 126px;
    margin: 0 auto;
}

.header-text{
  padding: 0 10px;
}

.button{
  color: #003399;
  margin: 0;
  position: relative;
  transition: var(--trans2,color .4s ease 0s);
  white-space: nowrap;
  font-size: 16px
}

.box{
  border: 1px solid #003399;
  border-radius: 5px;
  margin: 0 5px
}

 .link_btn{
   align-items: center;
   box-sizing: border-box;
   border-radius: 2px;
   display: flex;
   justify-content: var(--label-align);
   min-width: 100%;
   text-align: initial;
   width: 133px;
   height: 34px;
 }

  .link{
    color: #324158;
  }

 a{
   text-decoration: none;
 }

 .link:hover {
   color: #003399;
 }

 .link_btn:hover, .link_btn:hover span{
   background-color: #003399;
   color: white;

 }

:root{
--shd: none;
--fnt: normal normal normal 15px/1.4em roboto-bold,roboto,sans-serif;
--font_1: normal normal normal 12px/1.1em raleway,sans-serif;
--trans2: color 0.4s ease 0s;
--trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
--label-align: center;
}

@media screen and (max-width: 1150px) {
  .header_wrapper {
  padding: 0 80px 0px 26px;
}

  .logo{
    width: 200px;
  }

  .header-main_nav{
    column-gap: 22px;
    font: var(--fnt);
    font-weight: bold;
  }
}

@media screen and (max-width: 1025px) {

  .header_wrapper {
  padding: 0 80px 0px 26px;
}

  .logo{
    width: 202px;
  }

  .header-main_nav{
    column-gap: 22px;
  }

}

@media screen and (max-width: 850px) {
  .header-nav{
    display: none;
  }

  .header_wrapper {
    padding: 0 26px;
    gap:15px;
}

  .logo{
    width: 150px;
  }
}

@media screen and (max-width: 370px) {
.header_wrapper{
  padding: 0 19px 0px 19px;
  box-sizing: border-box;
  }
}

@media screen and (max-width: 320px) {
  .logo{
    width: 120px;
  }

  .link_btn{
    width: 130px
  }

  .header_wrapper{
    padding: 0 19px 0px 19px;
    box-sizing: border-box;
    }

}
