.end-info{
  padding: 22.3px 45px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #003399;
  border-radius: 9px;
  margin-top: 28px;
  color: #003399;
  position: relative;
  transition: var(--trans2,color .4s ease 0s);
}

.end-info:hover {
  background-color: #003399;
  color: white;
}

.box-info{
  margin: 0 24.5%;
}

.printDiscount{
  font-size: 20px;
}

.modal-text{
  color: black;
  font-weight: 100;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: sans-serif;
}

.modal-title{
  margin: 5px 0px;
}

.ant-btn-default {
    background-color: #003399;
    border-color: #003399;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    color: white;
}

.ant-btn-default:not(:disabled):hover {
    color: white;
    background: #003399;
    border-color: #003399;
}

.end-message {
  margin: 0 24.5%;
  font-size: 26px;
  color: #003399;
  text-align: center;
  font-weight: bolder;
}

.end-message.end-message-button{
  margin: 0px;
  display: grid;
  gap: 12px;
}

.end-message button {
  border: 0;
  outline: 0;
  width: 100%;
  background-color: white;
  height: 72px;
  padding: 0 15px;
  font-size: 18px;
  border-radius: 10px;
  color: #003399;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  border: 2px solid #003399;
}

.end-message-button button {
  margin-top: 28px;
}

.end-message button:hover{
  color: white;
  background: #003399;
  border: 2px solid #003399;
  transition: var(--trans1,color .4s ease 0s);
}

.switch-days-button {
  width: 4%;
  float:left;
}

.appointment-grid {
  width: 90%;
  float:left;
  border: 2px solid #205685;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.appointment-element {
  font-size: 22px;
  margin: 0 10%
}

.appointment-element-slot {
  font-size: 20px;
  border: solid;
  margin: 20px;
}

.checkbox-appointment-element-slot+.appointment-element-slot {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.checkbox-appointment-element-slot:hover+.appointment-element-slot { /*bord de la boite passage souris*/
  border: 1px solid #205685 ;
}

.checkbox-appointment-element-slot:checked+.appointment-element-slot { /*bord de la boite sélectionnée*/
  border: 2px solid #205685;
  background: #FFFFFF;
}

@media screen and (max-width: 767px) {
  .end-message {
    margin: 0px;
    font-size: 18px;
  }

  .box-info{
    margin: 0px;
  }

  .end-message-button button {
    font-size: 17px;
  }

  .end-info {
    font-size: 16px;
    padding: 25px 0px;
  }

  .appointment-grid {
    width: 73%;
    margin: 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .appointment-element {
    margin: 0px 8px;
    font-size: 16px;
  }

  #appointment-element-2{
    display: none;
  }

  .appointment-element-slot {
    padding-top: 12px;
    height: 30px;
    font-size: 14px;
    margin: 25px 0;
  }

  .switch-days-button {
    width: 10%;
    margin: 0 5px;
  }
}
