body {
  width: 100%;
}

.text{
  margin: 0px;
}

.footer-main_wrapper{
  max-width: 1740px;
  margin: 0 auto;
  padding: 35px 5% 20px;

}

.container {
    background-color: #003399;
    text-align: center;
    height: auto;
    width: auto;

}

.footer-link {
  display: grid;
  grid-gap: 23px;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.footer-main_footer h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
  color: white;
  text-align: start;
}

.footer-main_footer ul {
  display: table;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:30px;
  margin-top: -3px;
  padding: 0px
}

.footer-main_footer ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
  color: white;
}

.footer-main_footer ul a:hover {
  opacity:1;
}

.copyright {
    position: relative;
    color: white;
    margin-top: 44px;
    margin-bottom: 0;
    font-weight: bold;
    bottom: 35px;
}


.footer-item{
  width: 234px;
}

.footer-main{
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-item .para {
  font-size: 21px;
  text-align: start;
  color: white;
  font-weight:bold;
  margin: 11px
}

.footer-item .highlighted{
  background-color: white;
  color: #003399;
  margin: 0;
  width: 165px;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {

  .footer-link {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .footer-item .para{
    font-size: 23px;
  }

  .footer-item .highlighted {
    width: 186px;
  }

  .col-sm-4 .col-sm-3{
      display: grid;
      justify-items: center;
  }
}

@media screen and (width <= 575px) {

  .footer-link {
    grid-template-columns: 1fr;
  }

  .footer-item .para {
    font-size: 17px;
  }

  .footer-item .highlighted{
    width: 133px;
  }

  .footer-main_wrapper {
    padding: 35px 10% 20px;
  }
}

@media screen and (width <= 361px) {
  .footer-main{
    width: 50%
  }

  .footer-item .para {
    font-size: 21px;
  }

  .footer-item .highlighted{
    width: 165px;
  }
  .footer-main_wrapper{
    padding: 32px 34px 20px;
  }
}


@media screen and (min-width: 600px) {
  .footer-main_wrapper{
    padding: 35px 10% 20px;
  }
  .footer-link {
    justify-items: center;
}

}
